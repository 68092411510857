import stringifySafe from 'json-stringify-safe';
import { ChargeIntent } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { createSubmission } from '@wix/ambassador-forms-v4-submission/http';
import { Order, OrderStatus, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import { Money, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  checkoutStage,
  uouActionAtCheckout,
  couponErrorAtCheckout,
  planPurchased,
  pricingPlansUouCheckoutError,
  pricingPlansClientAuditLogSrc92Evid408,
} from '@wix/bi-logger-pricing-plans-data/v2';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import validators from '@wix/fed-sec-utils/validators/isEmail';
import type { FormValues } from '@wix/form-viewer';
import { initFormController } from '@wix/form-viewer/controller';
import { CheckoutData, IntegrationData, isCustomPurchaseFlow, toError } from '@wix/pricing-plans-router-utils';
import { getSetupFeeAmount, getSetupFeeName, hasSetupFee, isFreePlan, isRecurringPlan } from '@wix/pricing-plans-utils';
import { retry } from '@wix/pricing-plans-utils/http';
import { ControllerFlowAPI, ControllerParams, IUser, IWixAPI } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID, BOOKINGS_SECTION_ID } from '../../../constants';
import { WIX_FORMS_NAMESPACE } from '../../../constants/wix-forms';
import { getFormattedPriceData } from '../../../hooks';
import { BenefitsApi, OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import { PaymentMethodsApi } from '../../../services/payment-methods';
import { SettingsApi } from '../../../services/settings';
import { WarmupData } from '../../../services/WarmupData';
import {
  OnGetFullyDiscountedPlanFn,
  CheckoutProps,
  CommonProps,
  MessageCode,
  OnBeforeStartPaymentFn,
  PromisifyFn,
  ModalType,
  noModal,
  OnGetFreePlanFn,
  OnContinueAsGuestFn,
  CheckoutStage,
} from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { formatSpannedPrices } from '../../../utils';
import { CheckoutAction, CheckoutError, toBIPaymentType } from '../../../utils/bi';
import { ymdToDate } from '../../../utils/date';
import { withIgnoredErrors, withInteraction } from '../../../utils/decorators';
import { getOrderCoupon, getPricesCoupon } from '../../../utils/domainUtils';
import {
  errorToMessage,
  getApplicationErrorCode,
  getApplicationErrorData,
  PurchaseLimitExceededError,
} from '../../../utils/errors';
import { PaymentResultReader } from '../../../utils/PaymentResultReader';
import { resolveCountryCodeFromMultilingual, resolveLocale } from '../../../utils/resolve-locale';
import { getUserData } from '../../../utils/user';
import { captureViewerException } from '../../../utils/viewer-errors';
import { Router } from './Router';

enum CouponErrorCode {
  ERROR_COUPON_IS_DISABLED = 'ERROR_COUPON_IS_DISABLED',
  ERROR_COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED = 'ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED',
  ERROR_COUPON_IS_NOT_ACTIVE_YET = 'ERROR_COUPON_IS_NOT_ACTIVE_YET',
  ERROR_COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  ERROR_COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
  ERROR_COUPON_NOT_APPLICABLE_FOR_PLAN = 'ERROR_COUPON_NOT_APPLICABLE_FOR_PLAN',
  ERROR_COUPON_ALREADY_APPLIED = 'ERROR_COUPON_ALREADY_APPLIED',
  ERROR_INVALID_SUBTOTAL = 'ERROR_INVALID_SUBTOTAL',
  UNKNOWN = 'Unknown Error',
}

const couponErrorTranslationMap: { [key in CouponErrorCode]: string } = {
  [CouponErrorCode.ERROR_COUPON_IS_DISABLED]: 'payment.coupon-error.coupon-is-disabled',
  [CouponErrorCode.ERROR_COUPON_USAGE_EXCEEDED]: 'payment.coupon-error.coupon-usage-exceeded',
  [CouponErrorCode.ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED]: 'payment.coupon-error.coupon-limit-per-customer-exceeded',
  [CouponErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET]: 'payment.coupon-error.coupon-is-not-active-yet',
  [CouponErrorCode.ERROR_COUPON_HAS_EXPIRED]: 'payment.coupon-error.coupon-has-expired',
  [CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST]: 'payment.coupon-error.coupon-does-not-exists',
  [CouponErrorCode.ERROR_COUPON_NOT_APPLICABLE_FOR_PLAN]: 'payment.coupon-error.coupon-not-applicable-for-plan',
  [CouponErrorCode.ERROR_COUPON_ALREADY_APPLIED]: 'payment.coupon-error.coupon-already-applied',
  [CouponErrorCode.ERROR_INVALID_SUBTOTAL]: 'payment.coupon-error.invalid-subtotal',
  [CouponErrorCode.UNKNOWN]: 'payment.coupon-error.unknown',
};

const IGNORED_ERROR_CODES = [
  CouponErrorCode.ERROR_COUPON_USAGE_EXCEEDED,
  CouponErrorCode.ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED,
  CouponErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET,
  CouponErrorCode.ERROR_COUPON_HAS_EXPIRED,
  CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST,
  CouponErrorCode.ERROR_COUPON_NOT_APPLICABLE_FOR_PLAN,
];

const isIgnoredCouponError = (e: unknown) => IGNORED_ERROR_CODES.includes(getApplicationErrorCode(e));

const isPurchaseLimitExceededError = (e: unknown) => e instanceof PurchaseLimitExceededError;

export class CheckoutController {
  protected couponCode?: string;
  public initializeUser: (user: IUser) => Promise<void> = () => Promise.resolve(undefined);

  constructor(
    protected setProps: (props: Partial<CommonProps & CheckoutProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected router: Router,
    protected flowAPI: ControllerFlowAPI,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected benefitsApi: BenefitsApi,
    protected premiumApi: PremiumApi,
    protected paymentMethodsApi: PaymentMethodsApi,
    protected analytics: Analytics,
    protected settingsApi: SettingsApi,
    protected warmupData: WarmupData,
  ) {}

  async initialize(checkoutData: CheckoutData) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.CheckoutPageInitialized);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.CheckoutPageInitialized).start();
    const {
      currentUser: { loggedIn, id },
    } = this.wixCodeApi.user;
    const { planId, memberId, orderId, integrationData } = checkoutData;
    let plans: PublicPlan[] = [];
    try {
      plans = await this.warmupData.cache(`checkout.plan-${planId}`, () =>
        this.plansApi.loadPaidPlans({ planIds: [planId] }),
      );
    } catch (e) {
      this.captureException(e);
    }
    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.CheckoutPageInitialized);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.CheckoutPageInitialized).finish();

    if (plans.length < 1) {
      this.router.gotoList(integrationData, MessageCode.PLAN_NOT_FOUND);
    } else {
      const [selectedPlan] = plans;
      const isSameMember = loggedIn && memberId === id;
      try {
        await this.update(
          selectedPlan,
          integrationData,
          isSameMember && orderId
            ? await this.warmupData.cache(`checkout.order-${orderId}`, () => this.ordersApi.getOrder(orderId))
            : undefined,
        );
      } catch (e) {
        this.setProps({ messageCode: errorToMessage(toError(e)), isCheckoutDataInitialized: true });
      }
    }
  }

  async update(
    selectedPlan: PublicPlan,
    integrationData: IntegrationData,
    order?: Order,
    guestCheckoutFlag?: boolean,
  ): Promise<void> {
    this.registerInitializeUser(selectedPlan, order);

    this.setProps({
      order: undefined,
      isCheckoutDataInitialized: false,
      purchaseLimitExceeded: false,
    });

    this.analytics.viewContent(selectedPlan);
    const [hasCoupons, prices, benefits, guestCheckoutEnabled] = await this.warmupData.cache(
      `checkout.data-${selectedPlan.id}-${order?.id}`,
      () =>
        Promise.all([
          this.ordersApi.hasCoupons(),
          this.getPricePreview(selectedPlan, order),
          this.getBookingBenefits(selectedPlan),
          guestCheckoutFlag !== undefined
            ? Promise.resolve(guestCheckoutFlag)
            : this.isGuestCheckoutEnabled(integrationData),
        ]),
    );
    if (selectedPlan.formId) {
      await this.silentMonitoredSetupForm(selectedPlan.formId);
    }
    this.setProps({
      order,
      prices,
      selectedPlan,
      logout: this.logout,
      loginOnCheckout: () => this.wixCodeApi.user.promptLogin({ mode: 'login', modal: true }),
      signupOnCheckout: () => this.wixCodeApi.user.promptLogin({ mode: 'signup', modal: true }),
      navigateToStatus: this.navigateToStatus,
      benefits,
      trackInitiateCheckout: () => this.analytics.initiateCheckout(selectedPlan),
      trackSelectPayment: (id: string) => this.analytics.selectPayment(id),
      demoBuyNowClicked: () => this.navigateToDemoStatus({ plan: selectedPlan, integrationData, guestCheckoutEnabled }),
      biCheckoutStage: (stage) =>
        this.flowAPI.bi?.report(
          checkoutStage({ stage, planGuid: selectedPlan.id, guestCheckout: guestCheckoutEnabled }),
        ),
      biPlanPurchased: (result: PaymentCompleteResultPublic, purchasedOrder: Order) =>
        this.flowAPI.bi?.report(
          planPurchased({
            paymentStatus: result.clientStatus,
            paymentMethodType: result.paymentMethod,
            duration:
              (selectedPlan.pricing?.subscription
                ? selectedPlan.pricing.subscription.cycleCount
                : selectedPlan.pricing?.singlePaymentForDuration?.count) ?? undefined,
            paymentType: toBIPaymentType(!!selectedPlan.pricing?.subscription),
            planGuid: selectedPlan.id,
            currency: selectedPlan.pricing?.price?.currency,
            price: Math.round(parseFloat(selectedPlan.pricing?.price?.value ?? '0') * 100),
            membershipId: purchasedOrder.id,
            couponId: getOrderCoupon(purchasedOrder)?.id,
            transactionId: result.chargeId,
            setupFeeAmount: hasSetupFee(selectedPlan)
              ? Math.round(Number(getSetupFeeAmount(selectedPlan)) * 100)
              : undefined,
            setupFeeName: getSetupFeeName(selectedPlan),
          }),
        ),
      integrationData,
      updatePriceDetails: this.updatePriceDetails,
      updatePriceDetailsError: undefined,
      couponInputMode: 'trigger',
      couponCode: (this.couponCode = undefined),
      removeCoupon: this.removeCoupon,
      onBeforeStartPayment: this.onBeforeStartPayment,
      onBeforeStartPaymentStatus: undefined,
      updateStartDateError: undefined,
      applyCouponError: undefined,
      hasCoupons,
      onGetFreePlan: this.onGetFreePlan,
      onGetFullyDiscountedPlan: this.onGetFullyDiscountedPlan,
      zeroPricePlanCheckoutStatus: undefined,
      guestCheckoutEnabled,
      guestEmail: undefined,
      guestCheckoutLoading: false,
      guestEmailConfirmed: false,
      guestEmailError: undefined,
      onContinueAsGuest: this.onContinueAsGuest,
      onEditGuestEmail: () => this.setProps({ guestEmailConfirmed: false, purchaseLimitExceeded: false }),
      multilingualCountryCode: resolveCountryCodeFromMultilingual(this.wixCodeApi),
    });

    try {
      const { currentUser } = this.wixCodeApi.user;
      if (currentUser.loggedIn) {
        // @todo: s.dubinskas: maybe needs better refactoring, but for now its hotfix
        await this.updateUserInfo(currentUser);
      }

      await this.initializeUser?.(currentUser);
    } catch (e) {
      this.captureException(e);
    }
  }

  private formatMoney({ value, currency }: Money) {
    const { fullPrice } = getFormattedPriceData({
      createCurrencyFormatter: this.flowAPI.getCurrencyFormatter,
      locale: resolveLocale(this.wixCodeApi),
      value,
      currency,
    });
    return fullPrice;
  }

  private formatPrices(spannedPrices?: SpannedPrice[]) {
    return formatSpannedPrices({ spannedPrices, formatMoney: this.formatMoney.bind(this) });
  }

  private async getPricePreview(plan: PublicPlan, order?: Order) {
    const spannedPrices = order?.pricing?.prices ?? (await this.ordersApi.getPricePreview(plan.id!));
    return this.formatPrices(spannedPrices);
  }

  private setupForm = async (formId: string) => {
    this.setProps({ skipAdditionalInfoStep: true });
    try {
      const { isFormEmpty } = await initFormController(this.flowAPI, { formId, namespace: WIX_FORMS_NAMESPACE });

      this.setProps({ skipAdditionalInfoStep: Boolean(isFormEmpty(formId)) });
    } catch (e) {
      this.captureException(e);
    }
  };

  private silentMonitoredSetupForm = withIgnoredErrors(
    withInteraction(this.flowAPI, 'init_form_controller', this.setupForm),
  );

  private async getBookingBenefits(plan: PublicPlan) {
    try {
      const isBookingsInstalled = await this.wixCodeApi.site.isAppSectionInstalled({
        sectionId: BOOKINGS_SECTION_ID,
        appDefinitionId: BOOKINGS_APP_DEF_ID,
      });
      return isBookingsInstalled ? this.benefitsApi.listPlanBookingsBenefits(plan.id ?? '') : [];
    } catch (err) {
      // TODO: What to do when benefit request fails?
      return [];
    }
  }

  private async isGuestCheckoutEnabled(integrationData: IntegrationData): Promise<boolean> {
    try {
      const settings = await this.settingsApi.getSettings();
      const isCustomCheckout = isCustomPurchaseFlow(integrationData);
      return !isCustomCheckout && !!settings?.guestCheckout?.enableGuestCheckout;
    } catch (e) {
      this.captureException(e);
      return false;
    }
  }

  registerInitializeUser(selectedPlan: PublicPlan, order: Order | undefined) {
    this.initializeUser = async (user) => {
      try {
        if (user.loggedIn) {
          this.setProps({ isCheckoutDataInitialized: false });
        }

        const checkoutError = await this.getCheckoutError(selectedPlan);
        switch (checkoutError) {
          case CheckoutError.NO_PREMIUM:
            this.setProps({ shouldUpgrade: true });
            if (await this.isAdmin()) {
              this.setProps({ modal: { type: ModalType.Upgrade, onClose: this.closeUpgradeModal } });
              break;
            }

          // eslint-disable-next-line no-fallthrough
          case CheckoutError.NO_PAYMENT_METHOD:
          case CheckoutError.NO_RECURRING_PAYMENT_METHOD:
            this.openCannotAcceptPaymentsModal(checkoutError);
            break;
        }

        if (!checkoutError && user.loggedIn) {
          this.updateUserInfo(user);
          if (!order && this.flowAPI.environment.isViewer) {
            if (isFreePlan(selectedPlan)) {
              await this.createOrderForFreePlan(selectedPlan);
            } else {
              await this.createOrder(selectedPlan);
            }
          }
        }
      } catch (e) {
        this.captureException(e);
        if (e instanceof PurchaseLimitExceededError) {
          this.flowAPI.bi?.report(pricingPlansUouCheckoutError({ errorName: CheckoutError.PLAN_ALREADY_PURCHASED }));
          this.setProps({ purchaseLimitExceeded: true });
        } else {
          this.setProps({ messageCode: errorToMessage(toError(e)) });
        }
      } finally {
        this.setProps({ isCheckoutDataInitialized: true });
      }
    };
  }

  private getCheckoutError = async (selectedPlan: PublicPlan): Promise<CheckoutError | void> => {
    if (this.flowAPI.environment.isViewer) {
      if (
        !isFreePlan(selectedPlan) &&
        (await this.warmupData.cache('checkout.upgrade', () => this.premiumApi.shouldUpgrade()))
      ) {
        return CheckoutError.NO_PREMIUM;
      }

      if (!isFreePlan(selectedPlan)) {
        const supportedChargeIntents = await this.warmupData.cache('checkout.chargeIntents', () =>
          this.paymentMethodsApi.getSupportedChargeIntents(),
        );
        if (supportedChargeIntents.length === 0) {
          return CheckoutError.NO_PAYMENT_METHOD;
        }

        if (isRecurringPlan(selectedPlan) && !supportedChargeIntents.includes(ChargeIntent.RECURRING)) {
          return CheckoutError.NO_RECURRING_PAYMENT_METHOD;
        }
      }
    }
  };

  private isAdmin = async () => {
    try {
      const roles = await this.wixCodeApi.user.currentUser.getRoles();
      return roles.filter((role) => role?.name === 'Admin').length !== 0;
    } catch (e) {
      // getRoles throws an error if user isn't logged in
      return false;
    }
  };

  private closeUpgradeModal = () => {
    this.setProps({ modal: noModal, messageCode: MessageCode.CHECKOUT_DEMO });
  };

  private createOrderForFreePlan = async (plan: PublicPlan) => {
    try {
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'create order preview for free plan',
        entityId: '',
        data: { planId: plan.id },
      });
      const orderPreview = await this.ordersApi.createOrderPreview(plan.id!);
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'create order preview for free plan - success',
        entityId: orderPreview?.id ?? '',
        data: { planId: plan.id },
      });
      this.setProps({ order: orderPreview });
    } catch (e) {
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'create order preview for free plan - failed',
        entityId: '',
        data: { planId: plan.id, error: e },
      });
      throw e;
    }
  };

  private createOrder = async (plan: PublicPlan) => {
    try {
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'create order',
        entityId: '',
        data: { planId: plan.id },
      });
      const order = await this.ordersApi.createOrderIfNotOverLimit(plan.id!);
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'create order - success',
        entityId: order?.id ?? '',
        data: { planId: plan.id },
      });
      if (order) {
        const prices = this.couponCode ? await this.getPricesWithCoupon(plan) : order?.pricing?.prices;
        this.setProps({ order, prices: this.formatPrices(prices) });
      }
    } catch (e) {
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'create order - failed',
        entityId: '',
        data: { planId: plan.id, error: e },
      });
      throw e;
    }
  };

  private getPricesWithCoupon = async (selectedPlan: PublicPlan): Promise<SpannedPrice[] | undefined> => {
    try {
      return this.ordersApi.getPricePreview(selectedPlan.id!, this.couponCode);
    } catch (e) {
      this.setProps({ couponCode: (this.couponCode = undefined) });
      this.captureException(e);
    }
  };

  async updateUserInfo(user: IUser) {
    const userData = getUserData(user);
    try {
      const userEmail = await user.getEmail();
      this.setProps({ user: userData, userEmail });
    } catch (e) {
      this.setProps({ user: userData });
      this.captureException(new Error('Failed to get user email: ' + e));
    }
  }

  navigateToStatus: CheckoutProps['navigateToStatus'] = ({
    result,
    plan,
    order,
    integrationData,
    guestCheckoutEnabled,
  }) => {
    const resultReader = new PaymentResultReader(result);
    this.router.gotoStatus({
      plan,
      order,
      integrationData,
      config: {
        ok: resultReader.isOk(),
        error: resultReader.getTranslatedError(),
      },
      guestCheckoutEnabled,
    });
  };

  navigateToDemoStatus = (params: {
    plan: PublicPlan;
    integrationData: IntegrationData;
    guestCheckoutEnabled: boolean;
  }) =>
    this.router.gotoStatus({
      plan: params.plan,
      order: {},
      integrationData: params.integrationData,
      config: { ownerDemo: true },
      guestCheckoutEnabled: params.guestCheckoutEnabled,
    });

  logout = () => {
    this.wixCodeApi.user.logout();
  };

  private updateStartDate = async (orderId: string, dateYmd: string) => {
    this.setProps({
      updateStartDateError: undefined,
    });
    const date = ymdToDate(dateYmd);
    // @sarunas: this should be improved to allow reset start date to today
    if (date.getTime() >= Date.now()) {
      try {
        this.reportClientAuditLog({
          entityType: 'Order',
          message: 'change order start date',
          entityId: orderId,
          data: { date },
        });
        await this.ordersApi.updateOrderValidFrom(orderId, date);
      } catch (e) {
        this.setProps({
          updateStartDateError: {
            message: toError(e).message, // @todo: map it to actual error message
          },
        });
        this.reportClientAuditLog({
          entityType: 'Order',
          message: 'change order start date - failed',
          entityId: orderId,
          data: { date, error: e },
        });
        throw e;
      }
    }
  };

  updatePriceDetails = async (planId: string, couponCode: string) => {
    try {
      this.setProps({
        couponLoading: true,
        updatePriceDetailsError: undefined,
      });
      const prices = await this.ordersApi.getPricePreview(planId, couponCode);
      this.setProps({
        couponCode: (this.couponCode = couponCode),
        couponInputMode: 'coupon',
        couponLoading: false,
        prices: this.formatPrices(prices),
      });
      this.flowAPI.bi?.report(
        uouActionAtCheckout({
          action: CheckoutAction.ApplyCoupon,
          couponCode,
          couponId: getPricesCoupon(prices)?.id,
          planGuid: planId,
        }),
      );
    } catch (e) {
      this.captureException(e);
      const { message, code } = this.parseCouponError(e);
      this.setProps({ updatePriceDetailsError: { message }, couponLoading: false });
      this.flowAPI.bi?.report(
        couponErrorAtCheckout({
          action: CheckoutAction.ApplyCoupon,
          couponCode,
          planGuid: planId,
          errorField: code,
        }),
      );
    }
  };

  removeCoupon = async (planId: string, couponId?: string) => {
    this.setProps({
      couponCode: (this.couponCode = ''),
      couponInputMode: 'input',
      updatePriceDetailsError: undefined,
    });
    this.flowAPI.bi?.report(
      uouActionAtCheckout({
        action: CheckoutAction.RemoveCoupon,
        couponId,
        planGuid: planId,
      }),
    );
    try {
      this.setProps({
        prices: this.formatPrices(await this.ordersApi.getPricePreview(planId)),
      });
    } catch (e) {
      this.captureException(e);
    }
  };

  private applyCoupon = async (params: {
    orderId: string;
    couponCode: string;
    planId: string;
    isFullyDiscountedOneTimePlan?: boolean;
  }) => {
    const { orderId, couponCode, planId, isFullyDiscountedOneTimePlan } = params;
    this.setProps({
      applyCouponError: undefined,
    });
    try {
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'apply coupon',
        entityId: orderId,
        data: { couponCode },
      });
      const order = await this.ordersApi.applyCoupon(orderId, couponCode);
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'apply coupon - success',
        entityId: orderId,
        data: { couponCode },
      });
      this.setProps({
        order,
        couponCode: (this.couponCode = couponCode),
        prices: this.formatPrices(order?.pricing?.prices),
      });
      return order;
    } catch (e) {
      const { code, message } = this.parseCouponError(e);

      if (code === CouponErrorCode.ERROR_COUPON_ALREADY_APPLIED) {
        const appliedCoupon = getApplicationErrorData(e)?.couponCode;
        if (!isFullyDiscountedOneTimePlan && appliedCoupon && appliedCoupon === couponCode) {
          /*
            Checkout doesn't need to be stopped if the order
            is already discounted with the same coupon.
          */
          return;
        }
      }

      this.setProps({
        couponInputMode: 'input',
        applyCouponError: {
          message,
        },
      });
      this.flowAPI.bi?.report(
        couponErrorAtCheckout({
          action: CheckoutAction.ApplyCoupon,
          couponCode,
          planGuid: planId,
          errorField: code,
        }),
      );
      this.reportClientAuditLog({
        entityType: 'Order',
        message: 'apply coupon - failed',
        entityId: orderId,
        data: { couponCode, error: e },
      });
      throw e;
    }
  };

  private createSubmissionWithRetry = withInteraction(
    this.flowAPI,
    PackagePickerInteractions.CreateSubmission,
    async (formId: string, formValues: FormValues) => {
      try {
        this.reportClientAuditLog({
          entityType: 'Submission',
          message: 'create submission',
          entityId: '',
          data: { formId },
        });
        const response = await retry({ times: 2, delay: 500 }, () =>
          this.flowAPI.httpClient.request(
            createSubmission({
              submission: {
                formId,
                submissions: formValues,
              },
            }),
          ),
        );
        const { submission } = response.data;
        this.reportClientAuditLog({
          entityType: 'Submission',
          message: 'create submission - success',
          entityId: submission?.id!,
        });
        return submission;
      } catch (e) {
        this.reportClientAuditLog({
          entityType: 'Submission',
          message: 'create submission - failed',
          entityId: '',
          data: { formId, error: e },
        });
        throw e;
      }
    },
  );

  silentMonitoredCreateSubmission = withIgnoredErrors(this.createSubmissionWithRetry.bind(this));

  private setSubmissionWithRetry = withInteraction(
    this.flowAPI,
    PackagePickerInteractions.SetSubmission,
    (orderId: string, submissionId: string) => {
      return retry({ times: 2, delay: 500 }, () => {
        try {
          this.reportClientAuditLog({
            entityType: 'Order',
            message: 'set submission',
            entityId: orderId,
            data: { submissionId },
          });
          const updatedOrder = this.ordersApi.setSubmission(orderId, submissionId);
          this.reportClientAuditLog({
            entityType: 'Order',
            message: 'set submission - success',
            entityId: orderId,
            data: { submissionId },
          });
          return updatedOrder;
        } catch (e) {
          this.reportClientAuditLog({
            entityType: 'Order',
            message: 'set submission - failed',
            entityId: orderId,
            data: { submissionId, error: e },
          });
          throw e;
        }
      });
    },
  );

  silentMonitoredCreateAndAssignSubmission = withIgnoredErrors(this.createAndAssignSubmission.bind(this));

  private async createAndAssignSubmission(orderId: string, formId: string, formValues: FormValues) {
    const submission = await this.createSubmissionWithRetry(formId, formValues);
    const order = await this.setSubmissionWithRetry(orderId, submission?.id!);
    this.setProps({ order });
    return order;
  }

  onBeforeStartPayment: PromisifyFn<OnBeforeStartPaymentFn> = async (
    orderId,
    planId,
    { startDate, couponCode, formId, formValues },
  ) => {
    this.setProps({
      onBeforeStartPaymentStatus: undefined,
    });
    let success = true;
    try {
      if (startDate) {
        await this.updateStartDate(orderId, startDate);
      }
      if (formId && formValues) {
        await this.silentMonitoredCreateAndAssignSubmission(orderId, formId, formValues);
      }
      if (couponCode) {
        await this.applyCoupon({ orderId, planId, couponCode });
      }
    } catch (e) {
      this.captureException(e);
      success = false;
    }
    this.setProps({
      onBeforeStartPaymentStatus: { success },
    });
  };

  onGetFreePlan: OnGetFreePlanFn = async ({
    plan,
    integrationData,
    isGuest,
    guestEmail,
    guestCheckoutEnabled,
    formId,
    formValues,
  }) => {
    this.setProps({ zeroPricePlanCheckoutStatus: undefined });
    try {
      let order;
      if (isGuest) {
        order = await this.createGuestOrder(plan, guestEmail, formId, formValues);
      } else {
        let submissionId: string | undefined;
        if (formId && formValues) {
          const submission = await this.silentMonitoredCreateSubmission(formId, formValues);
          submissionId = submission?.id ?? undefined;
        }
        order = await this.ordersApi.createOrder(plan.id!, submissionId);
      }
      if (order) {
        this.setProps({ zeroPricePlanCheckoutStatus: { success: true } });
        this.reportZeroPricePlanPurchse('free_plan', plan, order);
        this.router.gotoStatus({ plan, order, integrationData, guestCheckoutEnabled });
      } else {
        this.setProps({ zeroPricePlanCheckoutStatus: { success: false } });
      }
    } catch (e) {
      this.captureException(e);
      this.setProps({ zeroPricePlanCheckoutStatus: { success: false } });
      if (e instanceof PurchaseLimitExceededError) {
        this.flowAPI.bi?.report(pricingPlansUouCheckoutError({ errorName: CheckoutError.PLAN_ALREADY_PURCHASED }));
        this.setProps({
          guestEmailConfirmed: false,
          purchaseLimitExceeded: true,
        });
      } else {
        this.setProps({ messageCode: errorToMessage(toError(e)) });
      }
    }
  };

  onGetFullyDiscountedPlan: PromisifyFn<OnGetFullyDiscountedPlanFn> = async ({
    orderId,
    plan,
    couponCode,
    integrationData,
    startDate,
    guestCheckoutEnabled,
    formId,
    formValues,
  }) => {
    this.setProps({ zeroPricePlanCheckoutStatus: undefined });
    try {
      if (startDate) {
        await this.updateStartDate(orderId, startDate);
      }
      if (orderId && formId && formValues) {
        await this.silentMonitoredCreateAndAssignSubmission(orderId, formId, formValues);
      }
      const order = await this.applyCoupon({
        orderId,
        planId: plan.id!,
        couponCode,
        isFullyDiscountedOneTimePlan: true,
      });
      if (order?.status === OrderStatus.PENDING || order?.status === OrderStatus.ACTIVE) {
        this.setProps({ zeroPricePlanCheckoutStatus: { success: true } });
        this.reportZeroPricePlanPurchse('full_discount', plan, order);
        this.router.gotoStatus({
          plan,
          order,
          integrationData,
          config: { ok: true },
          guestCheckoutEnabled,
        });
      } else {
        throw new Error('Invalid order status for discounted plan checkout: ' + order?.status);
      }
    } catch (e) {
      this.setProps({ messageCode: errorToMessage(toError(e)), zeroPricePlanCheckoutStatus: { success: false } });
      this.captureException(e);
    }
  };

  private reportZeroPricePlanPurchse = (
    zeroPricePlanType: 'free_plan' | 'full_discount',
    selectedPlan: PublicPlan,
    order: Order,
  ) =>
    this.flowAPI.bi?.report(
      planPurchased({
        paymentStatus: zeroPricePlanType,
        paymentMethodType: zeroPricePlanType,
        duration:
          (selectedPlan.pricing?.subscription
            ? selectedPlan.pricing.subscription.cycleCount
            : selectedPlan.pricing?.singlePaymentForDuration?.count) ?? undefined,
        paymentType: toBIPaymentType(!!selectedPlan.pricing?.subscription),
        planGuid: selectedPlan.id,
        currency: selectedPlan.pricing?.price?.currency,
        price: 0,
        membershipId: order.id,
        couponId: getOrderCoupon(order)?.id,
      }),
    );

  private openCannotAcceptPaymentsModal(errorName: CheckoutError) {
    this.flowAPI.bi?.report(pricingPlansUouCheckoutError({ errorName }));
    this.setProps({
      modal: {
        type: ModalType.CannotAcceptPayment,
        onClose: () => this.setProps({ modal: noModal }),
      },
    });
  }

  onContinueAsGuest: PromisifyFn<OnContinueAsGuestFn> = async ({ plan, email, skipOrderCreation }) => {
    this.setProps({ guestEmailError: undefined });

    if (!email) {
      this.setProps({ guestEmailError: 'required' });
      return;
    }

    if (!validators.isEmail(email)) {
      this.setProps({ guestEmailError: 'invalid' });
      return;
    }

    this.flowAPI.bi?.report(
      checkoutStage({
        planGuid: plan.id,
        guestCheckout: true,
        stage: isFreePlan(plan) ? CheckoutStage.GUEST_CHECKOUT_GET_FREE_PLAN : CheckoutStage.GUEST_CHECKOUT_CONTINUE,
      }),
    );

    if (skipOrderCreation) {
      this.setProps({
        guestCheckoutLoading: false,
        guestEmail: email,
        guestEmailConfirmed: true,
      });
      return;
    }

    try {
      this.setProps({ guestCheckoutLoading: true });
      if (isFreePlan(plan)) {
        this.setProps({
          guestCheckoutLoading: false,
          guestEmail: email,
          guestEmailConfirmed: true,
          prices: this.formatPrices(await this.getPricesWithCoupon(plan)),
        });
      } else {
        const order = await this.createGuestOrder(plan, email);
        if (order) {
          this.setProps({
            order,
            guestCheckoutLoading: false,
            guestEmail: email,
            guestEmailConfirmed: true,
            prices: this.formatPrices(await this.getPricesWithCoupon(plan)),
          });
        } else {
          this.setProps({ guestCheckoutLoading: false, guestEmailConfirmed: false });
        }
      }
    } catch (e) {
      this.captureException(e);
      if (e instanceof PurchaseLimitExceededError) {
        this.flowAPI.bi?.report(pricingPlansUouCheckoutError({ errorName: CheckoutError.PLAN_ALREADY_PURCHASED }));
        this.setProps({
          guestEmailConfirmed: false,
          guestCheckoutLoading: false,
          purchaseLimitExceeded: true,
        });
      } else {
        this.setProps({
          guestEmailConfirmed: false,
          guestCheckoutLoading: false,
          guestEmailError: undefined,
          messageCode: errorToMessage(toError(e)),
        });
      }
    }
  };

  private createGuestOrder = async (plan: PublicPlan, email?: string, formId?: string, formValues?: FormValues) => {
    if (!email) {
      return;
    }

    const isPurchaseLimitExceeded = await this.ordersApi.isLimitExceededForGuest(plan, email);
    if (isPurchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    const captchaToken = await this.requestCaptchaToken();
    if (captchaToken) {
      let submissionId: string | undefined;
      if (formId && formValues) {
        const submission = await this.silentMonitoredCreateSubmission(formId, formValues);
        submissionId = submission?.id ?? undefined;
      }
      try {
        this.reportClientAuditLog({
          entityType: 'Order',
          message: 'create guest order',
          entityId: '',
          data: { planId: plan.id, formId },
        });
        const order = await this.ordersApi.createGuestOrder({
          planId: plan.id!,
          email,
          captchaToken,
          submissionId,
        });
        this.reportClientAuditLog({
          entityType: 'Order',
          message: 'create guest order - success',
          entityId: order?.id ?? '',
          data: { planId: plan.id, formId },
        });
        return order;
      } catch (e) {
        this.reportClientAuditLog({
          entityType: 'Order',
          message: 'create guest order - failed',
          entityId: '',
          data: { planId: plan.id, formId, error: e },
        });
        throw e;
      }
    }
  };

  private requestCaptchaToken = async () => {
    type IWixApiWithAuthentication = IWixAPI & {
      authentication: { openCaptchaChallenge: () => Promise<string | null> };
    };

    try {
      this.reportClientAuditLog({
        entityType: 'Captcha',
        message: 'open captcha challenge',
        entityId: '',
      });
      const token = await (this.wixCodeApi as IWixApiWithAuthentication).authentication.openCaptchaChallenge();
      this.reportClientAuditLog({
        entityType: 'Captcha',
        message: 'open captcha challenge - success',
        entityId: '',
      });
      return token;
    } catch (e) {
      this.reportClientAuditLog({
        entityType: 'Captcha',
        message: 'open captcha challenge - failed',
        entityId: '',
        data: { error: e },
      });
      // openCaptchaChallenge promise is rejected if captcha modal is closed
      return null;
    }
  };

  private parseCouponError = (e: unknown): { message: string; code: CouponErrorCode } => {
    const code = getApplicationErrorCode(e) ?? CouponErrorCode.UNKNOWN;
    const messageKey =
      couponErrorTranslationMap[code as CouponErrorCode] ?? couponErrorTranslationMap[CouponErrorCode.UNKNOWN];
    return { code, message: this.flowAPI.translations.t(messageKey) };
  };

  private captureException = (e: unknown) => {
    captureViewerException(this.flowAPI, e, {
      additionalIgnorePredicates: [isIgnoredCouponError, isPurchaseLimitExceededError],
    });
  };

  private reportClientAuditLog = (params: {
    entityType: string;
    entityId: string;
    message: string;
    data?: Record<string, any>;
  }) => {
    this.flowAPI.bi?.report(
      pricingPlansClientAuditLogSrc92Evid408({
        ...params,
        data: params.data ? stringifySafe(params.data) : undefined,
      }),
    );
  };
}
